import { graphql } from 'gatsby';
import React from 'react';
import CheckList from '../../components/check-list';
import ServiceLayout from '../../components/service-layout';
import { pageContext } from '../../contexts/page-context';

const bizs = [
  {
    h: 'บริการประเมินมูลค่าธุรกิจ',
    t: 'จัดเตรียมรายงานการประเมินธุรกิจอย่างเป็นทางการสำหรับวัตถุประสงค์ต่าง ๆ เช่น การจัดหารายได้, ภาษี, เลิกร้าง และวัตถุประสงค์อื่น ๆ',
  },
  {
    h: 'การจัดกลยุทธ์ในการเพิ่มมูลค่า',
    t: 'ให้ไอเดียในการเพิ่มศักยภาพในการทำกำไรและกระแสเงินสดสำหรับธุรกิจของท่าน',
  },
  {
    h: 'การจัดแผนสวัสดิการและโบนัส',
    t: 'พิจารณาตัวเลือกที่เหมาะสมที่สุดสำหรับท่าน ธุรกิจของท่าน และพนักงานของท่าน',
  },
  {
    h: 'การวางแผนธุรกิจและการคาดการณ์สถานการณ์ทางการเงิน',
    t: 'แผนธุรกิจที่ดีจะต้องสามารถอธิบายถึงการเติบโตและสามารถคาดการณ์สถานการณ์การเงินได้อย่างแม่นยำ ซึ่งเราจะช่วยคุณในการกำหนดแผนที่เกี่ยวข้องกับการเงินและปรับปรุงให้ทันการณ์อย่างสม่ำเสมอ',
  },
  {
    h: 'บริการจัดวางโครงสร้างธุรกิจและการวางแผนภาษี',
    t: 'เราจะช่วยในการกำหนดหน่วยโครงสร้าง การให้คำปรึกษารายการทางการเงิน การปรับโครงสร้างความมั่งคั่งในครอบครัว โดยเราช่วยให้คุณเข้าใจถึงตัวเลือกต่าง ๆ และภาษีที่จะเกิดขึ้น เพื่อให้คุณสามารถวางแผนและเลือกทางได้อย่างถูกต้องและมั่นใจ',
  },
  {
    h: 'การประเมินและการเปรียบเทียบทางธุรกิจ',
    t: 'แสดงให้ท่านได้เห็นถึงผลลัพธ์ในการดำเนินงานทางธุรกิจของท่านเทียบกับบริษัทอื่น ๆ ในกลุ่มธุรกิจเดียวกัน ซึ่งจะช่วยให้ท่านสามารถหาจุดแข็งและจุดอ่อนขององค์กรของท่านได้',
  },
  {
    h: 'การวางแผนในการเลิกธุรกิจ',
    t: 'การเลือกทางออกทางธุรกิจที่ดีที่สุด ผ่านการประเมินต่าง ๆ ทั้งด้านบุคคล ครอบครัว เป้าหมายทางธุรกิจ และสิ่งต่าง ๆ ที่เกี่ยวข้อง',
  },
  {
    h: 'เป็นตัวแทนในการควบรวมกิจการ',
    t: 'คอยประสานงานกับเจ้าของกิจการและที่ปรึกษาเพื่อที่จะจัดการกระบวนการต่าง ๆ ในภาพรวม ตั้งแต่การกำหนดเป้าหมายธุรกิจจนกระทั่งกระบวนควบรวมกิจการเสร็จสิ้น',
  },
];

const individuals = [
  { h: 'การวางแผนภาษีที่ดินและสิ่งปลูกสร้าง' },
  { h: 'การวางแผนและการวิเคราะห์แผนการเกษียณ' },
  { h: 'การวางแผนและการจัดทำงบประมาณกระแสเงินสด' },
  { h: 'การบริหารแผนประกันและการจัดการความเสี่ยง' },
  { h: 'การวิเคราะห์แผนการลงทุนและการคาดการณ์เศรษฐกิจ' },
  { h: 'การวางแผนภาษีเงินได้' },
  { h: 'การวางแผนการศึกษา' },
  { h: 'การวางแผนกลยุทธ์สำหรับการเลิกจากธุรกิจ' },
  { h: 'การวางแผนทางการเงิน' },
  { h: 'การวางแผนการเกษียณ' },
  { h: 'การวางแผนประกันสังคม' },
  { h: 'การวางแผนและการทบทวนรายละเอียดแผนการประกันชีวิต' },
  { h: 'การวางแผนและการทบทวนรายละเอียดแผนการประกันทุพพลภาพ' },
  { h: 'การวางแผนความมั่งคั่งในครอบครัว' },
  { h: 'การวางแผนการบริจาค' },
];

export default function ConsultingServicePage({ data }) {
  return (
    <pageContext.Provider value={{ lang: 'th' }}>
      <ServiceLayout
        title={data.servicesThJson.name}
        subtitle={data.servicesThJson.description}
        url={data.servicesThJson.link}>
        <div className='text-black text-opacity-70'>
          ไม่ว่าจะเป็นการวางแผนซื้อบ้านใหม่ การลงทุนในการศึกษา การขายกิจการ การเกษียณ อสังหาริมทรัพย์ หรือ
          กิจกรรมทางการเงินต่าง ๆ เอเอฟ การบัญชี คือ ผู้ที่จะอยู่เคียงข้างคุณในทุกย่างก้าว เราช่วยเหลือทั้งบุคคล
          ครอบครัว และผู้ประกอบการต่าง ๆ ในการกำหนดเป้าหมายและพัฒนากลยุทธ์ทางธุรกิจที่เป็นไปได้
        </div>
        <CheckList header='ด้านธุรกิจองค์กร' list={bizs} />
        <CheckList header='ด้านบุคคล' list={individuals} />
      </ServiceLayout>
    </pageContext.Provider>
  );
}

export const query = graphql`
  query ConsultingServiceThQuery {
    servicesThJson(link: { eq: "/services/consulting" }) {
      name
      description
    }
  }
`;
